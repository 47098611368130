import React, { useState } from "react";
import isEmpty from "lodash/isEmpty";

import SelectedPackageSubscriptions from "./SelectedPackageSubscriptions";
import { ErrorMessage, PrimaryButton, Text, Textfield } from "widgets";
import { useFetchUpdatedPrice } from "store/backend/assets";
import { DEFAULT_COUNTRY_NAME } from "constants/config";
import { useSearchParams } from "react-router-dom";

import styles from "./OrderSummary.module.scss";

const calculateTotalMRP = (selectedPackageSubscriptions) => {
  const totalMrp = selectedPackageSubscriptions.reduce(
    (total, packageSubscription) => {
      const mrp = packageSubscription.mrp;
      return total + mrp;
    },
    0
  );
  return Number(totalMrp).toFixed(2);
};

const handleDynamicDiscount = (
  numericValue,
  isPercentage,
  setErrorMessage,
  handleChangeDiscounting
) => {
  setErrorMessage("");
  handleChangeDiscounting("discount_value", numericValue);
  handleChangeDiscounting("is_percentage", isPercentage);
  handleChangeDiscounting("discount_type", "DYNAMIC");
};

const handleCouponCode = (
  fieldValue,
  handleChangeDiscounting,
  setErrorMessage,
  setDisableApplyButton,
  customer_id
) => {
  setErrorMessage("");
  setDisableApplyButton(false);
  handleChangeDiscounting("coupon_code", fieldValue);
  handleChangeDiscounting("customer_id", customer_id);
  handleChangeDiscounting("discount_type", "COUPON_CODE");
};

const handleChangeDiscount = (
  fieldValue,
  handleChangeDiscounting,
  setErrorMessage,
  setDisableApplyButton,
  customer_id
) => {
  const isPercentage = fieldValue.includes("%");
  const numericValue = parseFloat(fieldValue.replace("%", "").trim());
  const isNumericFieldValue = /^[0-9]+%?$/.test(fieldValue.trim());

  if (isNumericFieldValue && !isNaN(numericValue)) {
    handleDynamicDiscount(
      numericValue,
      isPercentage,
      setErrorMessage,
      handleChangeDiscounting
    );
  } else {
    handleCouponCode(
      fieldValue,
      handleChangeDiscounting,
      setErrorMessage,
      setDisableApplyButton,
      customer_id
    );
  }
};

const handleRemoveDiscount = (
  setDiscountValue,
  setIsCouponApplied,
  handleChangeDiscounting,
  handleChangePriceDetailsAfterDiscount
) => {
  setDiscountValue("");
  setIsCouponApplied(false);
  handleChangeDiscounting("discount_value", null);
  handleChangeDiscounting("coupon_code", null);
  handleChangeDiscounting("discount_type", null);
  handleChangePriceDetailsAfterDiscount({});
};

const buildMutationVariables = (selectedPackageSubscriptions, discount) => {
  const skus = selectedPackageSubscriptions.map((packageSubscription) => ({
    id: packageSubscription.packageId,
    subscriptions: [
      {
        id: packageSubscription.id,
        price: packageSubscription.mrp,
      },
    ],
  }));

  const mutationVariables = {
    skus,
    platform_id: 1,
    discount,
  };

  return mutationVariables;
};

const getPriceAfterDiscount = (updatedPriceDetails) => {
  const priceAfterDiscount = updatedPriceDetails?.price_after_discount || null;
  if (priceAfterDiscount != null) {
    return Number(priceAfterDiscount).toFixed(2);
  } else {
    return null;
  }
};

const handleApplyDiscount = (
  discountValue,
  refetchUpdatedPrice,
  handleChangePriceDetailsAfterDiscount,
  setIsCouponApplied
) => {
  if (discountValue) {
    refetchUpdatedPrice()
      .then((result) => {
        handleChangePriceDetailsAfterDiscount({
          listedOrderAmount: result.data.total_order_amount,
          orderAmount: result.data.price_after_discount,
          subscription_discount_details:
            result.data.subscription_discount_details || [],
        });
        setIsCouponApplied(true);
      })
      .catch((err) => {
        console.error("Error fetching updated price:", err);
      });
  }
};

const renderAddress = (title, address) => {
  if (isEmpty(address)) {
    return null;
  }

  return (
    <div className={styles.addressBlock}>
      <Text>{title}</Text>
      <div className={styles.addressField}>
        <span className={styles.addressLabel}>Recipient Name:</span>
        {address.recipientName}
      </div>
      <div className={styles.addressField}>
        <span className={styles.addressLabel}>Phone Number:</span>
        {address.recipientPhoneNumber}
      </div>
      <div className={styles.addressField}>
        <span className={styles.addressLabel}>Address Line 1:</span>
        {address.addressLine1}
      </div>
      <div className={styles.addressField}>
        <span className={styles.addressLabel}>Address Line 2:</span>
        {address.addressLine2}
      </div>
      <div className={styles.addressField}>
        <span className={styles.addressLabel}>Street:</span>
        {address.street}
      </div>
      <div className={styles.addressField}>
        <span className={styles.addressLabel}>District:</span>
        {address.district}
      </div>
      <div className={styles.addressField}>
        <span className={styles.addressLabel}>City:</span>
        {address.city}
      </div>
      <div className={styles.addressField}>
        <span className={styles.addressLabel}>State:</span>
        {address.state}
      </div>
      <div className={styles.addressField}>
        <span className={styles.addressLabel}>Country:</span>
        {DEFAULT_COUNTRY_NAME}
      </div>
      <div className={styles.addressField}>
        <span className={styles.addressLabel}>Pincode:</span>
        {address.pincode}
      </div>
    </div>
  );
};

const OrderSummary = (props) => {
  const {
    handleChangePriceDetailsAfterDiscount,
    orderDetails,
    selectedPackageSubscriptions,
    handleChangePackageSubscriptionSelection,
    handleChangeDiscounting,
  } = props;

  const { billingAddress, shippingAddress, discount } = orderDetails;
  const mutationVariables = buildMutationVariables(
    selectedPackageSubscriptions,
    discount
  );
  const {
    isLoading: isUpdatedPriceLoading,
    data: updatedPriceDetails,
    refetch: refetchUpdatedPrice,
    error,
    isError,
  } = useFetchUpdatedPrice(mutationVariables);

  const totalMRP = calculateTotalMRP(selectedPackageSubscriptions);

  const [discountValue, setDiscountValue] = useState("");
  const [isCouponApplied, setIsCouponApplied] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [disableApplyButton, setDisableApplyButton] = useState(false);
  const [searchParams] = useSearchParams();
  const customer_id = searchParams.get("uam_id");

  return (
    <div className={styles.orderSummaryContainer}>
      <div className={styles.addressContainer}>
        {renderAddress("Billing Address", billingAddress)}
        {renderAddress("Shipping Address", shippingAddress)}
      </div>
      <div className={styles.packageContainer}>
        <SelectedPackageSubscriptions
          selectedPackageSubscriptions={selectedPackageSubscriptions}
          handleChangePackageSubscriptionSelection={
            handleChangePackageSubscriptionSelection
          }
        />
        <div className={styles.discountContainer}>
          <div className={styles.discountField}>
            <span className={styles.discountLabel}>Total Cart Value:</span>
            <span className={styles.amountValues}>
              {totalMRP.toLocaleString()}
            </span>
          </div>
          <div className={styles.discountField}>
            <div className={styles.discountLabel}>Discount/Coupon:</div>
            <div className={styles.discountTextFieldWithErrorMessage}>
              <div className={styles.discountFieldAndButton}>
                <div className={styles.discountTextField}>
                  <Textfield
                    placeholder="Discount/Coupon"
                    value={discountValue}
                    handleOnChange={(event) => {
                      const newValue = event.target.value;
                      setDiscountValue(newValue);
                      handleChangeDiscount(
                        newValue,
                        handleChangeDiscounting,
                        setErrorMessage,
                        setDisableApplyButton,
                        customer_id
                      );
                    }}
                    disabled={isCouponApplied}
                  />
                </div>
                {isCouponApplied ? (
                  <PrimaryButton
                    onClick={() =>
                      handleRemoveDiscount(
                        setDiscountValue,
                        setIsCouponApplied,
                        handleChangeDiscounting,
                        handleChangePriceDetailsAfterDiscount
                      )
                    }
                  >
                    Remove
                  </PrimaryButton>
                ) : (
                  <PrimaryButton
                    disabled={!discountValue || disableApplyButton}
                    onClick={() =>
                      handleApplyDiscount(
                        discountValue,
                        refetchUpdatedPrice,
                        handleChangePriceDetailsAfterDiscount,
                        setIsCouponApplied
                      )
                    }
                  >
                    Apply
                  </PrimaryButton>
                )}
              </div>
              {isError || errorMessage ? (
                <ErrorMessage>
                  {isError ? error.message : errorMessage}
                </ErrorMessage>
              ) : null}
            </div>
          </div>
          <hr className={styles.horizontalLine} />
          <div className={styles.discountField}>
            <span className={styles.discountLabel}>Grand Total:</span>
            {isUpdatedPriceLoading ? (
              <span className={styles.amountValues}>
                Grand Total Loading...
              </span>
            ) : (
              <span className={styles.amountValues}>
                {getPriceAfterDiscount(updatedPriceDetails)?.toLocaleString() ||
                  totalMRP}
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderSummary;
