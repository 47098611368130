import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";

import OnlinePayment from "components/OnlinePayment/OnlinePayment";
import LoanPayment from "components/LoanPayment/LoanPayment";
import { DetailDisplay, Dialog, ErrorMessage, Toggle } from "widgets";
import PaymentDetails from "components/PaymentDetails/PaymentDetails";
import {
  useSelectedOrderDetails,
} from "store/backend/assets";
import DisplayOrder from "components/DisplayOrder/DisplayOrder";
import Loader from "Shared/Loader/Loader";
import {
  INR_SYMBOL,
  ONGOING_AND_SUCCESSFUL_REFUND_STATUSES,
  ONGOING_AND_SUCCESSFULL__PAYMENT_STATUSES,
  PAYMENT_STATUSES,
} from "constants/config";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import AddNote from "components/AddNote/AddNote";

import styles from "./PaymentPreference.module.scss";

const SWITCH_TO_LOAN_PAYMENT = "Switch to Loan Payment method";
const SWITCH_TO_ONLINE_PAYMENT = "Switch to Online Payment method";

const handleTogglePaymentMethod = (event, setIsOnlinePayment) => {
  setIsOnlinePayment(event.target.checked);
};

const handleDialogClose = (setOpenDialogContent) => () => {
  setOpenDialogContent(null);
};

const handleOnClickAddNote = (setOpenDialogContent) => {
  setOpenDialogContent(true);
};

const PaymentPreference = () => {
  const [searchParams] = useSearchParams();
  const uam_id = searchParams.get("uam_id");
  const phone_number = searchParams.get("phone_number");
  const orderId = searchParams.get("order_id");
  const [isOnlinePayment, setIsOnlinePayment] = useState(true);
  const [openDialogContent, setOpenDialogContent] = useState(null);

  const {
    isPending: isSelectedOrderDetailsLoading,
    data: selectedOrderDetails,
    isError: isErrorOnSelectedOrderDetails,
    error: errorMessageOnSelectedOrderDetails,
  } = useSelectedOrderDetails(orderId);

  if (isSelectedOrderDetailsLoading) {
    return <Loader />;
  }

  if (isErrorOnSelectedOrderDetails) {
    return <div>{errorMessageOnSelectedOrderDetails.message}</div>;
  }

  if (!selectedOrderDetails) {
    return (
      <ErrorMessage>
        Order Details not found for Order Id {orderId}
      </ErrorMessage>
    );
  }

  const totalAmount = selectedOrderDetails.inr_conversion_amount;

  const totalOngoingAndSuccessfulPaymentAmount =
    selectedOrderDetails.payment_details
      .filter((payment) =>
        ONGOING_AND_SUCCESSFULL__PAYMENT_STATUSES.includes(payment.status.id)
      )
      .reduce((sum, payment) => sum + payment.amount, 0);

  const totalSuccessfulRefundedAmount = selectedOrderDetails.refund_details
    .filter((refund) =>
      ONGOING_AND_SUCCESSFUL_REFUND_STATUSES.includes(refund.status.id)
    )
    .reduce((sum, refund) => sum + refund.refunded_amount, 0);

  const pendingAmount =
    totalAmount -
    totalOngoingAndSuccessfulPaymentAmount +
    totalSuccessfulRefundedAmount;

  const paymentUnderProcessing =
    selectedOrderDetails.payment_details.length > 0
      ? selectedOrderDetails.payment_details
          .filter((payment) => payment.status.id === PAYMENT_STATUSES.CREATED)
          .reduce((sum, payment) => sum + payment.amount, 0)
      : 0;

  return (
    <div className={styles.mainContainer}>
      <div className={styles.contentWrapper}>
        <div className={styles.paymentArea}>
          {pendingAmount !== 0 && isOnlinePayment ? (
            <OnlinePayment
              totalAmount={totalAmount}
              payableAmount={pendingAmount}
              orderId={orderId}
            />
          ) : paymentUnderProcessing === 0 ? (
            <LoanPayment
              selected_order_id={orderId}
              pendingAmount={pendingAmount}
              totalAmount={totalAmount}
            />
          ) : (
            <ErrorMessage>
              Loan payment is currently unavailable as a payment is still being
              processed.
            </ErrorMessage>
          )}
        </div>

        <div className={styles.orderDetails}>
          <div className={styles.amountDetails}>
            <DetailDisplay
              title="Total Order Amount"
              value={INR_SYMBOL + Number(totalAmount).toFixed(2)}
            />
            <DetailDisplay
              title="Total Amount Paid and Under Processing"
              value={
                INR_SYMBOL + Number(totalAmount - pendingAmount).toFixed(2)
              }
            />
            <DetailDisplay
              title="Total Pending Amount"
              value={INR_SYMBOL + Math.abs(Number(pendingAmount)).toFixed(2)}
            />
          </div>
          <DisplayOrder
            selectedPackageSubscriptions={selectedOrderDetails.packages}
          />
          <div
            className={styles.addNote}
            onClick={() => handleOnClickAddNote(setOpenDialogContent)}
          >
            Add a Note?
            <AddCircleIcon />
          </div>
          {selectedOrderDetails && (
            <PaymentDetails
              paymentDetails={selectedOrderDetails.payment_details}
            />
          )}
        </div>
      </div>

      <div className={styles.toggle}>
        <Toggle
          checked={isOnlinePayment}
          onChange={(event) =>
            handleTogglePaymentMethod(event, setIsOnlinePayment)
          }
          label={
            isOnlinePayment ? SWITCH_TO_LOAN_PAYMENT : SWITCH_TO_ONLINE_PAYMENT
          }
        />
      </div>
      <Dialog open={openDialogContent !== null} height="180px">
        <AddNote
          onClose={handleDialogClose(setOpenDialogContent)}
          orderId={orderId}
        />
      </Dialog>
    </div>
  );
};

export default PaymentPreference;
