import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";

const StyledTable = styled(Table)(() => ({
  "& th": {
    backgroundColor: "#007bff",
    color: "#ffffff",
    fontWeight: "bold",
    textAlign: "center",
    whiteSpace: "nowrap",
    position: "sticky", 
    top: 0, 
    zIndex: 1,        
  },
}));

const StyledTableRow = styled(TableRow)(() => ({}));

const StyledTableCell = styled(TableCell)(() => ({
  borderRight: "1px solid rgba(224, 224, 224, 1)",
  "&:last-child": {
    borderRight: 0,
  },
  minWidth: "80px",
  alignItems: "center",
}));
const CustomTable = ({ columns, rows }) => {
  return (
    <TableContainer component={Paper}>
      <StyledTable aria-label="dynamic table">
        <TableHead>
          <StyledTableRow>
            {columns.map((column) => (
              <StyledTableCell key={column.label} align="center">
                {column.label}
              </StyledTableCell>
            ))}
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, rowIndex) => (
            <StyledTableRow key={rowIndex}>
              {columns.map((column, colIndex) => (
                <StyledTableCell key={colIndex} align={column.align || "left"}>
                  {row[column.field]}
                </StyledTableCell>
              ))}
            </StyledTableRow>
          ))}
        </TableBody>
      </StyledTable>
    </TableContainer>
  );
};
export default CustomTable;
