import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import {
  useFetchCustomerInfo,
  useFetchCustomerOrderDetails,
  useSelectedOrderDetails,
} from "store/backend/assets";
import { ErrorMessage, PhoneNumberInput, PrimaryButton, Text } from "widgets";
import Loader from "Shared/Loader/Loader";
import PageNotFound from "components/PageNotFound";
import EventNoteIcon from "@mui/icons-material/EventNote";
import CustomerOrderDetails from "components/CustomerOrderDetails/CustomerOrderDetails";
import CustomerDetails from "components/CustomerDetails/CustomerDetails";

import styles from "./CustomerOrder.module.scss";

const isViewSearchButtonEnabled = (phoneNumber) => {
  const digits = phoneNumber.replace(/\D/g, "");
  return digits.length === 12;
};

const handleClickSearchButton = (phoneNumber) => {
  const cleanedPhoneNumber = phoneNumber.replace(/^(\d{2})(\d{10})$/, "$2");
  const newUrl = `/customerOrders?phone_number=${cleanedPhoneNumber}`;
  window.location.href = newUrl;
};

const handlePhoneNumberChange = (
  phoneNumber,
  setPhoneNumber,
  setCountryCode
) => {
  const countryCode = phoneNumber.match(/^\+(\d+)/)?.[1] || "";
  const phoneNumberWithoutCountryCode = phoneNumber
    .replace(`+${countryCode}`, "")
    .trim();
  setPhoneNumber(phoneNumberWithoutCountryCode);
  setCountryCode(countryCode);
};

const handleOnClickPunchOrderButton = (navigate, uam_id, user_id) => {
  if (user_id) {
    navigate(`/punchOrder?uam_id=${user_id}`);
    return;
  }
  navigate(`/punchOrder?uam_id=${uam_id}`);
  return;
};
const CustomerOrder = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const uam_id = searchParams.get("uam_id");
  const phone_number = searchParams.get("phone_number");
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [selectedOrderID, setSelectedOrderID] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [countryCode, setCountryCode] = useState("");

  const {
    isLoading: isfetchCustomerInformationLoading,
    data: customerDetails,
    isError: isErrorOnCustomerDetails,
    error: errorMessageOnCustomerDetails,
  } = useFetchCustomerInfo(phone_number);

  const {
    isPending: isOrderDetailsLoading,
    data: orderDetails,
    isError: isErrorOnCustomerOrderDetails,
    error: errorMessageOnCustomerOrderDetails,
  } = useFetchCustomerOrderDetails({ uam_id, phone_number });

  const {
    isPending: isSelectedOrderDetailsLoading,
    data: selectedOrderDetails,
    isError: isErrorOnSelectedOrderDetails,
    error: errorMessageOnSelectedOrderDetails,
  } = useSelectedOrderDetails(selectedOrderID);

  useEffect(() => {
    if (orderDetails && orderDetails.length > 0) {
      const orderId = orderDetails[0].order_id;
      setSelectedOrderID(orderId);
    }
  }, [orderDetails]);

  useEffect(() => {
    if (
      selectedOrderID &&
      !isSelectedOrderDetailsLoading &&
      !isErrorOnSelectedOrderDetails
    ) {
      setSelectedOrder(selectedOrderDetails);
    }
  }, [selectedOrderID, isSelectedOrderDetailsLoading]);

  if (!uam_id && !phone_number) {
    return <PageNotFound />;
  }

  if (
    (phoneNumber && isfetchCustomerInformationLoading) ||
    isOrderDetailsLoading
  ) {
    return <Loader />;
  }

  if (isErrorOnSelectedOrderDetails) {
    return (
      <ErrorMessage>{errorMessageOnSelectedOrderDetails.message}</ErrorMessage>
    );
  }

  const user_id = customerDetails?.user_id;
  const orderStatus = selectedOrder?.status?.id || null;

  return (
    <div className={styles.mainContainer}>
      {orderDetails && orderDetails.length > 0 ? (
        <>
          {isOrderDetailsLoading ? (
            <Loader />
          ) : (
            <div className={styles.customerDetailsAndPackageDetailsContainer}>
              <CustomerDetails
                selectedOrderID={selectedOrderID}
                uam_id={uam_id}
                user_id={user_id}
                errorMessageOnCustomerDetails={errorMessageOnCustomerDetails}
                orderDetails={orderDetails}
                isOrderDetailsLoading={isOrderDetailsLoading}
                isErrorOnCustomerDetails={isErrorOnCustomerDetails}
              />
              <CustomerOrderDetails
                orderId={selectedOrderID}
                orderDetails={orderDetails}
                selectedOrder={selectedOrder}
                setSelectedOrderID={setSelectedOrderID}
                setSelectedOrder={setSelectedOrder}
                orderStatus={orderStatus}
                phone_number={phone_number}
                uam_id={uam_id || user_id}
              />
            </div>
          )}
        </>
      ) : (
        <div className={styles.punchOrderButtonContainer}>
          {isErrorOnCustomerOrderDetails ? (
            <ErrorMessage>
              {errorMessageOnCustomerOrderDetails.message}
            </ErrorMessage>
          ) : (
            <>
              <div className={styles.noOrdersFoundText}>No orders found!</div>
              <div>Please punch a new order</div>
              <PrimaryButton
                onClick={() =>
                  handleOnClickPunchOrderButton(navigate, uam_id, user_id)
                }
              >
                Punch Order
              </PrimaryButton>
            </>
          )}
        </div>
      )}
      <div className={styles.phoneNumberInputAndNoteContainer}>
        <div className={styles.phoneNumberInputContainer}>
          <Text>Search Customer</Text>
          <PhoneNumberInput
            value={phoneNumber}
            onChange={(event) =>
              handlePhoneNumberChange(event, setPhoneNumber, setCountryCode)
            }
            onlyCountries={["in"]}
          />
          <PrimaryButton
            onClick={() => handleClickSearchButton(phoneNumber)}
            disabled={!phoneNumber || !isViewSearchButtonEnabled(phoneNumber)}
          >
            Search
          </PrimaryButton>
        </div>
        {selectedOrder && selectedOrder.note && (
          <div className={styles.displayNoteContainer}>
            <div className={styles.noteIconAndTitle}>
              <EventNoteIcon />
              <Text>Note</Text>
            </div>
            <div className={styles.note}>
              {selectedOrder && selectedOrder.note}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomerOrder;
