import React, { useState } from "react";

import {
  ErrorMessage,
  PrimaryButton,
  SingleSelect,
  Text,
  Textfield,
} from "widgets";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { INDIAN_CURRENCY_ID } from "constants/config";
import { useCreatePaymentLink } from "store/backend/assets";

import styles from "./OnlinePayment.module.scss";
import Loader from "Shared/Loader/Loader";

const AMOUNT_TYPES = [
  {
    key: 1,
    label: "Full Amount",
    value: "Full Amount",
  },
  {
    key: 2,
    label: "Custom Amount",
    value: "Custom Amount",
  },
];
const FULL_AMOUNT = "Full Amount";
const CUSTOM_AMOUNT = "Custom Amount";
const CUSTOM_AMOUNT_INDEX = 1;

const getDefaultSelected = (totalAmount, payableAmount) => {
  if (totalAmount === payableAmount) {
    return FULL_AMOUNT;
  }
  return CUSTOM_AMOUNT;
};

const handleAmountTypeChange = (
  event,
  setAmountType,
  setCustomAmount,
  setPaymentLink
) => {
  const selectedValue = event.target.value;
  setAmountType(selectedValue);
  setCustomAmount("");
  setPaymentLink("");
};

const handleCreatePaymentLink = (
  orderId,
  amountType,
  payableAmount,
  customAmount,
  setPaymentLink,
  setIsLinkCopied,
  createPaymentLink,
  isDownpayment
) => {
  const amount = isDownpayment
    ? payableAmount
    : amountType === FULL_AMOUNT
    ? payableAmount
    : customAmount;

  const paymentTypeId = isDownpayment
    ? AMOUNT_TYPES[CUSTOM_AMOUNT_INDEX].key
    : AMOUNT_TYPES.find((type) => type.value === amountType)?.key;

  const payload = {
    order_id: orderId,
    payment_link_amount: amount,
    currency_id: INDIAN_CURRENCY_ID,
    payment_type_id: paymentTypeId,
  };

  createPaymentLink(payload, {
    onSuccess: (paymentLink) => {
      setIsLinkCopied(false);
      setPaymentLink(paymentLink);
    },
  });
};

const handleCopyLink = (paymentLink, setIsLinkCopied) => {
  navigator.clipboard.writeText(paymentLink);
  setIsLinkCopied(true);
};

const isFormValid = (
  amountType,
  payableAmount,
  customAmount,
  isDownpayment
) => {
  if (payableAmount === 0) {
    return false;
  }
  if (isDownpayment) {
    return payableAmount > 0;
  }
  if (amountType === FULL_AMOUNT) {
    return payableAmount > 0;
  }
  if (customAmount > payableAmount) {
    return false;
  }
  return customAmount;
};

const OnlinePayment = ({
  totalAmount,
  payableAmount,
  orderId,
  isDownpayment,
}) => {
  const [amountType, setAmountType] = useState(
    getDefaultSelected(totalAmount, payableAmount)
  );
  const [customAmount, setCustomAmount] = useState(null);
  const [paymentLink, setPaymentLink] = useState("");
  const [isLinkCopied, setIsLinkCopied] = useState(false);
  const {
    mutate: createPaymentLink,
    isPending: isCreatePaymentLinkLoading,
    isError,
    error,
  } = useCreatePaymentLink();

  return (
    <div className={styles.onlinePaymentContainer}>
      {!isDownpayment ? (
        <>
          <Text>Select Amount Type</Text>
          <SingleSelect
            handleChange={(event) =>
              handleAmountTypeChange(
                event,
                setAmountType,
                setCustomAmount,
                setPaymentLink
              )
            }
            value={amountType}
            defaultSelected={amountType}
            menuItems={
              totalAmount === payableAmount
                ? AMOUNT_TYPES
                : [AMOUNT_TYPES[CUSTOM_AMOUNT_INDEX]]
            }
          />
        </>
      ) : (
        <>
          <SingleSelect
            value={CUSTOM_AMOUNT}
            menuItems={[AMOUNT_TYPES[CUSTOM_AMOUNT_INDEX]]}
            disabled
          />
        </>
      )}

      <Text>Payment Amount</Text>
      <Textfield
        value={
          isDownpayment
            ? payableAmount
            : amountType === FULL_AMOUNT
            ? totalAmount
            : customAmount
        }
        disabled={amountType === FULL_AMOUNT || isDownpayment}
        handleOnChange={(event) => setCustomAmount(event.target.value)}
        label="Enter Custom Amount"
      />
      {isDownpayment ? (
        payableAmount > 0
      ) : amountType === CUSTOM_AMOUNT && !customAmount ? (
        <ErrorMessage>Please enter a valid number</ErrorMessage>
      ) : null}
      {isCreatePaymentLinkLoading ? (
        <Loader />
      ) : (
        <PrimaryButton
          onClick={() =>
            handleCreatePaymentLink(
              orderId,
              amountType,
              payableAmount,
              customAmount,
              setPaymentLink,
              setIsLinkCopied,
              createPaymentLink,
              isDownpayment
            )
          }
          disabled={
            !isFormValid(amountType, payableAmount, customAmount, isDownpayment)
          }
        >
          Create Payment Link
        </PrimaryButton>
      )}
      {isError && <ErrorMessage>{error.message}</ErrorMessage>}

      {paymentLink && (
        <div className={styles.paymentLinkContainer}>
          <div className={styles.paymentLink}>{paymentLink}</div>
          <ContentCopyIcon
            className={styles.copyIcon}
            onClick={() => handleCopyLink(paymentLink, setIsLinkCopied)}
          />
          {isLinkCopied && <Text>Copied!</Text>}
        </div>
      )}
    </div>
  );
};

export default OnlinePayment;
