import React, { useState } from "react";
import { Formik } from "formik";
import { z } from "zod";
import { toFormikValidationSchema } from "zod-formik-adapter";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { camelizeKeys } from "utils/camelizeKeys";

import { Dialog, PrimaryButton, SecondaryButton, Stepper } from "widgets";
import PackageSubscription from "components/PackageSubscription/PackageSubscription";
import AddressForm from "components/AddressForm/AddressForm";
import OrderSummary from "components/OrderSummary/OrderSummary";
import ConfirmOrder from "components/ConfirmOrder/ConfirmOrder";
import { usePunchOrder, useRequestTransferOrder } from "store/backend/assets";
import PageNotFound from "components/PageNotFound";
import { HARDWARE_PRODUCT_TYPE_ID } from "constants/config";
import ReviewTransferOrder from "components/ReviewTransferOrder/ReviewTransferOrder";
import RequestTransferOrder from "components/RequestTransferOrder/RequestTransferOrder";

import styles from "./PunchOrder.module.scss";

const PACKAGE_SUBSCRIPTION_SELECTION = 0;
const ADDRESS_FORM = 1;
const ORDERSUMMARY_AND_DISCOUNT_APPLICATION = 2;
const REVIEW_TRANSFER_ORDER = 3;

const STEPS_LABEL = [
  { label: "Select Packages" },
  { label: "Fill Address" },
  { label: "Order Summary" },
];

const getInitialValues = () => {
  return {
    orderDetails: {
      billingAddress: {},
      shippingAddress: undefined,
      discount: {},
      hasHardware: false,
    },
    priceDetailsAfterDiscount: {},
    selectedPackageSubscriptions: [],
    currentStep: PACKAGE_SUBSCRIPTION_SELECTION,
    filters: {
      selectedGrade: [],
      selectedTargetExam: [],
    },
    searchTerm: "",
    useSameAddress: false,
    openDialog: false,
  };
};
const buildDiscountPayload = (discountDetails) => {
  if (!discountDetails) {
    return null;
  }

  const discountPayload = {
    discount_value: 0,
    is_percentage: false,
    discount_type: "",
    coupon_code: "",
    customer_id: "",
  };

  if (discountDetails.discount_type) {
    discountPayload.discount_type = discountDetails.discount_type;

    if (discountDetails.discount_type === "DYNAMIC") {
      discountPayload.discount_value = discountDetails.discount_value;
      discountPayload.is_percentage = discountDetails.is_percentage;
    } else if (discountDetails.discount_type === "COUPON_CODE") {
      if (discountDetails.coupon_code) {
        discountPayload.coupon_code = discountDetails.coupon_code;
        discountPayload.customer_id = discountDetails.customer_id;
      } else {
        return null;
      }
    }
  }

  return discountPayload;
};
const buildSkuPayload = (
  selectedPackageSubscriptions,
  priceDetailsAfterDiscount
) => {
  const discountDetails =
    priceDetailsAfterDiscount.subscription_discount_details || [];

  return selectedPackageSubscriptions.reduce((acc, selectedPackage) => {
    const matchingDiscount = discountDetails.find(
      (discount) => discount.subscription_id === selectedPackage.id
    );

    const subscriptionAmount = matchingDiscount
      ? selectedPackage.mrp - matchingDiscount.discount_amount
      : selectedPackage.mrp;

    const existingPackage = acc.find(
      (result) => result.id === selectedPackage.packageId
    );

    if (existingPackage) {
      return acc.map((result) =>
        result.id === selectedPackage.packageId
          ? {
              ...result,
              subscriptions: [
                ...result.subscriptions,
                {
                  id: selectedPackage.id,
                  listed_amount: Number(selectedPackage.mrp).toFixed(2),
                  subscription_amount: Number(subscriptionAmount).toFixed(2),
                },
              ],
            }
          : result
      );
    } else {
      return [
        ...acc,
        {
          id: selectedPackage.packageId,
          subscriptions: [
            {
              id: selectedPackage.id,
              listed_amount: Number(selectedPackage.mrp).toFixed(2),
              subscription_amount: Number(subscriptionAmount).toFixed(2),
            },
          ],
        },
      ];
    }
  }, []);
};

const buildPayload = (values, uam_id, sales_person_id,orderId
  = null) => {
  const {
    orderDetails,
    selectedPackageSubscriptions,
    priceDetailsAfterDiscount,
  } = values;

  const totalMrp = selectedPackageSubscriptions.reduce(
    (total, packageSubscription) => {
      return total + (packageSubscription.mrp || 0);
    },
    0
  );

  const listedOrderAmount =
    priceDetailsAfterDiscount.listedOrderAmount || totalMrp;

  const orderAmount = priceDetailsAfterDiscount.orderAmount || totalMrp;

  const discountPayload = buildDiscountPayload(orderDetails.discount);

  const discount =
    !!discountPayload && !!discountPayload.discount_type ? discountPayload : null;

  const billing_address = {
    district_id: orderDetails.billingAddress.districtId,
    street: orderDetails.billingAddress.street ? orderDetails.billingAddress.street.trim() : "",
    country_id: 1,
    postal_code_id: orderDetails.billingAddress.pincodeId,
    city: orderDetails.billingAddress.city.trim(),
    state_id: orderDetails.billingAddress.stateId,
    address_line_1: orderDetails.billingAddress.addressLine1.trim(),
    address_line_2: orderDetails.billingAddress.addressLine2 ? orderDetails.billingAddress.addressLine2.trim() : "",
    recipient_name: orderDetails.billingAddress.recipientName.trim(),
    recipient_phone_number: orderDetails.billingAddress.recipientPhoneNumber,
  };

  const shipping_address = values.useSameAddress
    ? billing_address
    : orderDetails.shippingAddress
    ? {
        district_id: orderDetails.shippingAddress.districtId,
        street: orderDetails.billingAddress.street ? orderDetails.billingAddress.street.trim() : "",
        country_id: 1,
        postal_code_id: orderDetails.shippingAddress.pincodeId,
        city: orderDetails.shippingAddress.city.trim(),
        state_id: orderDetails.shippingAddress.stateId,
        address_line_1: orderDetails.billingAddress.addressLine1.trim(),
        address_line_2: orderDetails.billingAddress.addressLine2 ? orderDetails.billingAddress.addressLine2.trim() : "",
        recipient_name: orderDetails.shippingAddress.recipientName.trim(),
        recipient_phone_number:
          orderDetails.shippingAddress.recipientPhoneNumber,
      }
    : null;

  return {
    listed_order_amount: Number(listedOrderAmount).toFixed(2),
    order_amount: Number(orderAmount).toFixed(2),
    business_unit_id: 1,
    order_punched_by: sales_person_id,
    office_location_id: 1,
    platform_id: 1,
    currency_id: 1,
    skus: buildSkuPayload(
      selectedPackageSubscriptions,
      priceDetailsAfterDiscount
    ),
    customer_id: uam_id,
    billing_address: billing_address,
    shipping_address: shipping_address,
    discount: discount,
  };
};

const handleClickNextButton = (
  currentStep,
  setFieldValue,
  selectedPackageSubscriptions
) => {
  setFieldValue("currentStep", currentStep + 1);

  const hasHardware = selectedPackageSubscriptions.some((pkg) =>
    pkg.componentProductTypeIds.includes(HARDWARE_PRODUCT_TYPE_ID)
  );
  setFieldValue("orderDetails.hasHardware", hasHardware);
};

const handleUseSameAddress = (useSameAddress, setFieldValue, orderDetails) => {
  const newUseSameAddress = !useSameAddress;
  setFieldValue("useSameAddress", newUseSameAddress);

  if (newUseSameAddress) {
    setFieldValue("orderDetails", {
      ...orderDetails,
      shippingAddress: orderDetails.billingAddress,
    });
  } else {
    setFieldValue("orderDetails", {
      ...orderDetails,
      shippingAddress: {},
    });
  }
};

const addressSchema = z.object({
  recipientName: z
    .string()
    .min(1, "Recipient Name is required")
    .max(50, "Recipient Name cannot be more than 50 characters"),
  recipientPhoneNumber: z
    .string()
    .min(10, "Phone number must be at least 10 digits")
    .regex(/^\d+$/, "Invalid phone number"),
  addressLine1: z
    .string()
    .min(1, "Address Line 1 is required")
    .max(100, "Address Line 1 cannot be more than 100 characters"),
  city: z.string().min(1, "City is required"),
  pincode: z
    .string()
    .max(6, "Pincode must be at most 6 digits")
    .regex(/^\d{6}$/, "Pincode must be 5 or 6 digits"),
});

const orderDetailsHasHardware = z.object({
  billingAddress: addressSchema,
  shippingAddress: addressSchema,
  hasHardware: z.literal(true),
});

const orderDetailsHasNoHardware = z.object({
  billingAddress: addressSchema,
  shippingAddress: z.optional(addressSchema),
  hasHardware: z.literal(false),
});
const formValidationSchema = z.object({
  orderDetails: z.discriminatedUnion("hasHardware", [
    orderDetailsHasHardware,
    orderDetailsHasNoHardware,
  ]),
});
const handleConfirmOrder = (
  values,
  navigate,
  punchOrder,
  uam_id,
  sales_person_id
) => {
  const payload = buildPayload(values, uam_id, sales_person_id);
  punchOrder(payload, {
    onSuccess: (orderId) => {
      navigate(`/paymentPreference?uam_id=${uam_id}&order_id=${orderId}`);
    },
    onError: (error) => {
      console.error("Error punching the order:", error);
    },
  });
};

const handleRequestTransferOrder=(values,
  navigate,
  requestTransferOrder,
  uam_id,
  sales_person_id,
  orderId
)=>{
  const payload = buildPayload(values,uam_id,sales_person_id,orderId
  )
  const requestTransferOrderPayload = {...payload, order_id: orderId}
  requestTransferOrder(requestTransferOrderPayload,{
    onSuccess: () =>{
      navigate(`/customerOrders?uam_id=${uam_id}`);
    },
    onError:(error)=>{
      console.error("Error requesting transfer order",error);
    }
  })
}
const getPackageSubscriptions = (
  selectedPackageSubscriptions,
  transferedPackages
) => {
  if (selectedPackageSubscriptions.length > 0) {
    return selectedPackageSubscriptions;
  }

  return transferedPackages.flatMap((pkg) => {
    if (!pkg.subscriptions || pkg.subscriptions.length === 0) {
      return [];
    }

    return pkg.subscriptions.map((subscription) => {
      return {
        id: subscription.id,
        packageId: pkg.id,
        displayName: pkg.displayName,
        planName: subscription.planCategory
        .name,
        planValidity: pkg.validityDate,
        mrp: subscription.mrp,
        componentProductTypeIds:
          subscription.components?.map(
            (component) => component.componentProduct.componentProductTypeId
          ) || [],
      };
    });
  });
};

const handleDialogOpen = (setFieldValue, component) => () => {
  setFieldValue("openDialog", component);
};

const PunchOrder = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const uam_id = searchParams.get("uam_id");
  const [sales_person_id] = useState(localStorage.getItem("sales_person_id"));
  const location = useLocation();
  const transferOrderDetails = location.state?.transferOrderDetails
  ? camelizeKeys(location.state.transferOrderDetails)
  : null;
  const isOrderTransfer =
    transferOrderDetails && Object.keys(transferOrderDetails).length > 0;
  const stepsLabels = isOrderTransfer
  ? [...STEPS_LABEL, { label: "Review Transfer Order" }]
  : STEPS_LABEL;  

  const {
    mutate: punchOrder,
    isPending: isPunchOrderLoading,
    isError,
    error,
  } = usePunchOrder();
  const { 
    mutate: requestTransferOrder,
    isPending: requestTransferOrderLoading,
    isError: requestTransferOrderIsError,
    error: requestTransferOrderError
  } = useRequestTransferOrder()

  if (!uam_id) {
    return <PageNotFound />;
  }

  return (
    <Formik
      initialValues={getInitialValues()}
      validationSchema={toFormikValidationSchema(formValidationSchema)}
    >
      {(formikProps) => {
        const {
          values,
          errors,
          setFieldValue,
          handleBlur,
          isValid,
          isSubmitting,
        } = formikProps;

        const {
          orderDetails,
          currentStep,
          selectedPackageSubscriptions,
          filters,
          searchTerm,
          useSameAddress,
          openDialog,
          priceDetailsAfterDiscount,
        } = values;
        const packageSubscriptions = getPackageSubscriptions(
          selectedPackageSubscriptions,
          transferOrderDetails?.packages || []
        );

        return (
          <div className={styles.root}>
            <Stepper activeStep={currentStep}>
              <Stepper.Steps steps={stepsLabels} activeStep={currentStep} />
            </Stepper>
            <div className={styles.stepContentContainer}>
              {currentStep === PACKAGE_SUBSCRIPTION_SELECTION ? (
                <PackageSubscription
                  selectedPackageSubscriptions={packageSubscriptions}
                  filters={filters}
                  searchTerm={searchTerm}
                  onPackagesSelected={(packages) =>
                    setFieldValue("selectedPackageSubscriptions", packages)
                  }
                  onFilterChange={(filterType, value) => {
                    setFieldValue(`filters.${filterType}`, value);
                  }}
                  onSearchTermChange={(value) => {
                    setFieldValue("searchTerm", value);
                  }}
                  handleChangePackageSubscriptionSelection={(packages) =>
                    setFieldValue("selectedPackageSubscriptions", packages)
                  }
                />
              ) : null}
            </div>
            <div className={styles.stepAddressFormContentContainer}>
              {currentStep === ADDRESS_FORM ? (
                <AddressForm
                  billingAddress={orderDetails.billingAddress}
                  shippingAddress={orderDetails.shippingAddress}
                  handleChangeBillingAddress={(fieldName, fieldValue) =>
                    setFieldValue(
                      `orderDetails.billingAddress.${fieldName}`,
                      fieldValue
                    )
                  }
                  handleChangeShippingAddress={(fieldName, fieldValue) =>
                    setFieldValue(
                      `orderDetails.shippingAddress.${fieldName}`,
                      fieldValue
                    )
                  }
                  useSameAddress={useSameAddress}
                  handleUseSameAddress={() =>
                    handleUseSameAddress(
                      useSameAddress,
                      setFieldValue,
                      orderDetails
                    )
                  }
                  hasHardware={orderDetails.hasHardware}
                  errors={errors}
                  handleBlur={handleBlur}
                  isOrderTransfer={isOrderTransfer}
                />
              ) : null}
              {currentStep === ORDERSUMMARY_AND_DISCOUNT_APPLICATION ? (
                <OrderSummary
                  handleChangePriceDetailsAfterDiscount={(
                    priceDetailsAfterDiscount
                  ) =>
                    setFieldValue(
                      "priceDetailsAfterDiscount",
                      priceDetailsAfterDiscount
                    )
                  }
                  orderDetails={orderDetails}
                  selectedPackageSubscriptions={selectedPackageSubscriptions}
                  handleChangePackageSubscriptionSelection={(packages) =>
                    setFieldValue("selectedPackageSubscriptions", packages)
                  }
                  handleChangeDiscounting={(fieldName, fieldValue) =>
                    setFieldValue(
                      `orderDetails.discount.${fieldName}`,
                      fieldValue
                    )
                  }
                />
              ) : null}
              {isOrderTransfer && currentStep === REVIEW_TRANSFER_ORDER && (
                <ReviewTransferOrder
                  transferOrderDetails={transferOrderDetails}
                  billingAddress={orderDetails.billingAddress}
                  selectedPackageSubscriptions={selectedPackageSubscriptions}
                  discountDetails={orderDetails.discount}
                  newOrderAmount={priceDetailsAfterDiscount.orderAmount}
                />
              )}
            </div>
            <div className={styles.lineItemWrapper}>
              {currentStep > PACKAGE_SUBSCRIPTION_SELECTION && (
                <SecondaryButton
                  onClick={() => setFieldValue("currentStep", currentStep - 1)}
                >
                  Go Back
                </SecondaryButton>
              )}
              {currentStep === ORDERSUMMARY_AND_DISCOUNT_APPLICATION &&
              !isOrderTransfer ? (
                <PrimaryButton
                  loading={isSubmitting}
                  onClick={handleDialogOpen(
                    setFieldValue,
                    <ConfirmOrder
                      onConfirm={() =>
                        handleConfirmOrder(
                          values,
                          navigate,
                          punchOrder,
                          uam_id,
                          sales_person_id
                        )
                      }
                      onCancel={() => setFieldValue("openDialog", false)}
                      isPending={isPunchOrderLoading}
                      error={error}
                      isError={isError}
                    />
                  )}
                  disabled={selectedPackageSubscriptions.length === 0}
                >
                  Confirm Order
                </PrimaryButton>
              ) : (
                currentStep !== REVIEW_TRANSFER_ORDER && (
                  <PrimaryButton
                    loading={isSubmitting}
                    disabled={
                      selectedPackageSubscriptions.length === 0 ||
                      (currentStep === ADDRESS_FORM && !isValid)
                    }
                    onClick={() =>
                      handleClickNextButton(
                        currentStep,
                        setFieldValue,
                        selectedPackageSubscriptions
                      )
                    }
                  >
                    Save & Continue
                  </PrimaryButton>
                )
              )}

              {currentStep === REVIEW_TRANSFER_ORDER ? (
                <PrimaryButton
                  onClick={handleDialogOpen(
                    setFieldValue,
                    <RequestTransferOrder
                    onConfirm={() =>
                        handleRequestTransferOrder(
                          values,
                          navigate,
                          requestTransferOrder,
                          uam_id,
                          sales_person_id,
                          transferOrderDetails.orderId
                        )
                      }
                    onCancel={() => setFieldValue("openDialog", false)}
                    isPending={requestTransferOrderLoading}
                    error={requestTransferOrderError}
                    isError={requestTransferOrderIsError}
                    />
                  )}
                >
                  Confirm Modification
                </PrimaryButton>
              ) : null}
            </div>
            <Dialog
              open={!!openDialog}
              handleClose={() => setFieldValue("openDialog", false)}
              width="500px"
              height="120px"
            >
              {openDialog}
            </Dialog>
          </div>
        );
      }}
    </Formik>
  );
};

export default PunchOrder;
