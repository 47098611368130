export const LOGIN_ENDPOINT = process.env.REACT_APP_LOGIN_ENDPOINT;
export const LOGIN_PRODUCT_ID = process.env.REACT_APP_LOGIN_PRODUCT_ID;
export const LOGIN_TENANT_ID = process.env.REACT_APP_LOGIN_TENANT_ID;
export const PLATFORM = process.env.REACT_APP_PLATFORM;
export const ISD_CODE = process.env.REACT_APP_ISD_CODE;
export const REQUEST_PRODUCT_ID = process.env.REACT_APP_REQUEST_PRODUCT_ID;
export const VALID_SALES_ROLE_IDS = {
  [Number(process.env.REACT_APP_VALID_SALES_ROLE_ID)]: "salesportal",
  [Number(process.env.REACT_APP_INTERNAL_RENEWAL_SALES)]: "internal_renewal_sales",
  [Number(process.env.REACT_APP_INTERNAL_TELE_SALES)]: "internal_tele_sales",
};

export const INDIAN_CURRENCY_ID = 1;
export const ORDER_STATUSES = {
  ACCEPTED: 1,
  FULFILLED: 2,
  DISCARDED: 3,
  CANCELLATION_REQUESTED: 4,
  CANCELLED: 5,
  SUSPENDED: 6,
};

export const PAYMENT_STATUSES = {
  CREATED: 1,
  VERIFIED: 2,
  ACTIVATED_BY_PROVIDER: 3,
  REJECTED_BY_PROVIDER: 4,
  CANCELLED_BY_CUSTOMER: 5,
  REJECTED_BY_INFINITY_LEARN: 6,
  APPROVED_BY_PROVIDER: 7,
  DISBURSED: 8,
};

export const REFUND_STATUSES = {
  CREATED: 1,
  SUCCEEDED: 2,
};

export const ONGOING_AND_SUCCESSFULL__PAYMENT_STATUSES = [
  PAYMENT_STATUSES.CREATED,
  PAYMENT_STATUSES.VERIFIED,
  PAYMENT_STATUSES.ACTIVATED_BY_PROVIDER,
  PAYMENT_STATUSES.APPROVED_BY_PROVIDER,
  PAYMENT_STATUSES.DISBURSED,
];

export const ONGOING_AND_SUCCESSFUL_REFUND_STATUSES = [
  REFUND_STATUSES.SUCCEEDED,
];

export const ONGOING_PAYMENT_STATUS = [PAYMENT_STATUSES.CREATED];

export const PENDING_ORDER_STATUS = [ORDER_STATUSES.ACCEPTED, ORDER_STATUSES.CANCELLATION_REQUESTED, ORDER_STATUSES.SUSPENDED]

export const SUCCESSFULL__PAYMENT_STATUSES = [
  PAYMENT_STATUSES.VERIFIED,
  PAYMENT_STATUSES.ACTIVATED_BY_PROVIDER,
  PAYMENT_STATUSES.APPROVED_BY_PROVIDER,
  PAYMENT_STATUSES.DISBURSED,
];

export const PACKAGE_DETAILS_COLUMN = [
  { label: "Package Name", field: "name" },
  { label: "Package Price", field: "price" },
  { label: "Discount", field: "discount" },
  { label: "Duration (days)", field: "duration" },
  { label: "Grade", field: "grade" },
];
export const PAYMENT_DETAILS_COLUMN = [
  { label: "Amount", field: "amount" },
  { label: "Status", field: "status" },
  { label: "Payment Date", field: "paymentDate" },
  { label: "Payment Method", field: "paymentMethod" },
  { label: "Receipt", field: "receipt" },
];
export const REFUND_DETAILS_COLUMN = [
  { label: "Refund Amount", field: "amount" },
  { label: "Status", field: "status" },
  { label: "Time Stamp", field: "refundDate"},
  { label: "Refund Reason", field: "refundReason"}
]
export const ONGOING_PAYMENT_STATUSES = [
  PAYMENT_STATUSES.CREATED,
  PAYMENT_STATUSES.VERIFIED,
  PAYMENT_STATUSES.ACTIVATED_BY_PROVIDER,
];

export const X_TENANT = "infinitylearn";
export const HARDWARE_PRODUCT_TYPE_ID = 7;
export const DEFAULT_COUNTRY_NAME = "India";

export const INR_SYMBOL = "₹ ";

export const ONE_RUPEE = 1